import Cookies from "js-cookie";

export const getCookie = (key) => {
    const val = Cookies.get(key);
    // console.log(val);
    return val? JSON.parse(val): null;
}

export const setCookie = (key, val) => {
    return Cookies.set(key, JSON.stringify(val));
}

export const removeCookie = (key) => {
    return Cookies.remove(key);
}

export const useCookies = () => {
    const cookies = Cookies;

    const get = (key) => {
        return cookies.get(key);
    }

    const set = (key, val) => {
        return cookies.set(key, val);
    }

    const remove = (key) => {
        cookies.remove(key);
    }

    return {
        get,
        set,
        remove,
    }
}