import { createApp, markRaw } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import {createPinia} from 'pinia'
import vuetify from './utils/vuetify'

const pinia = createPinia();

pinia.use(({ store }) => {
    store.router = markRaw(router);
});

createApp(App)
    .use(router)
    .use(pinia)
    .use(vuetify)
    .mount('#app')
