import { createVuetify } from 'vuetify/lib/framework.mjs'
import * as comps from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {
    VDataTable,
    VDataTableServer
} from "vuetify/labs/VDataTable";
import { VDataIterator } from 'vuetify/lib/labs/components.mjs';
import { VDatePicker } from 'vuetify/lib/labs/components.mjs';

export default createVuetify({
    components: {
        ...comps,
        VDataIterator,
        VDataTable,
        VDataTableServer,
        VDatePicker
    },
    directives,
})