import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

export const useCanvas = defineStore('canvas', () => {
    const isOpen = ref(false);
    const open = ref({
        view: false,
        action: false
    });

    const active = ref("");

    async function show(name){
        open.value[active.value] = false;
        // if(active.value){
        //     await this.router.push('.');
        // }

        open.value[name] = true;

        active.value = name;
    }

    function hide(route = null){
        for(const i in open.value){
            open.value[i] = false;
        }

        // if(route){
        //     this.router.push('.');
        // }

        if(route){
            this.router.push({
                name: route
            });
        }
        
        active.value = "";
    }

    return {
        isOpen,
        open,
        show,
        hide
    }
})