<script setup>

</script>

<template>
  <router-view/>
</template>

<style>
@import url('/public/assets/css/index.css');
@import 'bootstrap';
@import 'vuetify';
@import 'font-awesome';
@import 'toastr';
</style>
