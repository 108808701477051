import { createRouter, createWebHistory } from "vue-router";
import { getCookie } from "@/utils/useCookies";
import { useCanvas } from "@/store/useCanvas";

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("../views/Main.vue"),
    meta: {
      auth: true,
      title: "Dashboard",
    },
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("../components/modules/Dashboard.vue"),
        meta: {
          // title: 'Dashboard'
        },
      },
      {
        path: "/leads",
        name: "leads",
        component: () => import("../components/modules/leads/Leads.vue"),
        meta: {
          auth: true,
          title: "Leads",
        },
        children: [
          {
            path: ":id",
            name: "lead",
            component: () => import("../components/modules/leads/Lead.vue"),
            meta: {
              auth: true,
              title: "Lead",
              canvas: true,
            },
            props: true,
          },
        ],
      },
      {
        path: "/follow_ups",
        name: "follow_ups",
        component: () =>
          import("../components/modules/followUps/FollowUps.vue"),
        meta: {
          auth: true,
          title: "Follow Ups",
        },
        children: [
          {
            path: ":id",
            name: "follow_up",
            component: () =>
              import("../components/modules/followUps/FollowUp.vue"),
            meta: {
              auth: true,
              title: "Follow Up",
              canvas: true,
            },
            props: true,
            children: [
              {
                path: "action/:actionId",
                name: "action",
                component: () =>
                  import("../components/modules/followUps/actions/Action.vue"),
                meta: {
                  title: "Action",
                },
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: "/services",
        name: "services",
        component: () => import("../components/modules/services/Services.vue"),
        meta: {
          auth: true,
          title: "Services",
        },
        children: [
          {
            path: ":id",
            name: "service",
            component: () =>
              import("../components/modules/services/Service.vue"),
            meta: {
              auth: true,
              title: "Service",
              canvas: true,
            },
          },
        ],
      },
      {
        path: ":serviceId/policies",
        name: "policies",
        component: () => import("../components/modules/policies/Policies.vue"),
        props: true,
        meta: {
          auth: true,
          title: "Policies",
        },
        children: [
          {
            path: ":id",
            name: "policy",
            component: () =>
              import("../components/modules/policies/Policy.vue"),
            props: true,
            meta: {
              auth: true,
              title: "Policy",
              canvas: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "Login",
      auth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(/*process.env.VUE_APP_BASE_URL*/),
  routes,
});

const redirectLink = "/";

router.beforeEach(async (to, from, next) => {
  let authToken = getCookie("auth");
  if (to.meta.title) {
    document.title = `${to.meta.title} | ${process.env.VUE_APP_NAME}`;
  }

  const canvas = useCanvas();
  if (to.meta.canvas) {
    canvas.show("view");
  } else {
    canvas.hide();
  }

  if (to.meta.auth) {
    if (!authToken) {
      next("login");
    } else {
      next();
    }
  } else {
    if (authToken) {
      next(from.path || redirectLink);
    } else {
      next();
    }
  }

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
